<template>
  <div v-if="useBg" class="img-container icon-white" :style="{backgroundImage: 'url(' + getImage() + ')'}"></div>
  <img v-else :src="getImage()" :alt="alt">
</template>

<script type="plain/text">
import FileService from "@/scripts/services/file.service";
import CampaignTestimony from "@/scripts/models/campaignTestimony.model";

export default {
  props: {
    alt: "",
    testimony: {},
    useBg: false
  },
  data() {
    return {
      loaded: false,
      fileService: new FileService()
    };
  },
  created() {
    this.renderImage();
  },
  methods: {
    renderImage() {
      this.fileService.render(this.testimony.file.id).then(data => {
        this.$set(this.testimony, "imageContent", data);
        if (data != null && data != "") this.loaded = true;
      });
    },
    getImage() {
      if (this.testimony) {
        if (this.testimony.hasOwnProperty("imageContent")) {
          return this.testimony.imageContent;
        } else if (this.loaded) {
          this.loaded = false;
          this.renderImage();
        }
      }
      return "";
    }
  }
};
</script>