import File from "@/scripts/models/file.model";

export default class TestimonyVolunteering {

	constructor() {
		this.id = 0;
		this.volunteeringId = 0;
		this.file = new File();
		this.name = '';
		this.testimony = '';
		this.city = '';
		this.uf = '';
		this.role = '';		
	}
}