export default class CoverageVolunteering {

  constructor() {
      this.Order = 0;
      this.id = 0;
      this.name = "";
      this.volunteeringId = 0;
      this.lat = 0.0;
      this.long = 0.0;
  }

}